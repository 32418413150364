'use client';
import { ModalHeader, Modal, useDisclosure, ModalContent, ModalBody, JsonSchema } from '.';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/zoom';
import '../styles/customSwiper.css';
import { EventTrackingModel } from '@alfred-co/types';
import { useState } from 'react';

interface EditDataModalProps {
  isOpen?: boolean
  onClose?: () => void
  labor: string
  title: string
  event: EventTrackingModel
  onSave?: (data: any) => Promise<void>
}

export const EditDataModal = (
  { isOpen: isOpenI, onClose: onCloseI, labor, title, event, onSave }: EditDataModalProps
) => {
  const { isOpen, onOpenChange } = useDisclosure({
    ...isOpenI && { isOpen: isOpenI },
    ...onCloseI && { onClose: onCloseI },
  });
  const [isEditLoading, setIsEditLoading] = useState(false);

  const handleSave = async (data: any) => {
    setIsEditLoading(true);
    await onSave?.(data);
    onOpenChange();
    setIsEditLoading(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      isDismissable={false}
      isKeyboardDismissDisabled
      size='3xl'
      classNames={{
        base: ['bg-frosted-gradient backdrop-blur frosty text-white rounded-lg border-1 border-complementary-gray-light h-full'],
        closeButton: ['bg-dark-dark hover:bg-dark-main p-1 m-1 [&>svg]:h-5 [&>svg]:w-5'],
      }}
      scrollBehavior='inside'
    >
      <ModalContent>
        <ModalHeader className='flex flex-col items-start gap-1'>
          {title}
        </ModalHeader>
        <ModalBody>
          <div className='flex flex-col my-5 h-full'>
            <div className='bg-secondary text-secondary-50 bg-clip-text mb-6 font-gothamBold uppercase'>
              {labor}
            </div>
            <div className='flex flex-col flex-grow w-full pb-10 gap-5'>
              <JsonSchema
                onSave={handleSave}
                values={event.data}
                schema={event.eventType.schema}
                isLoading={isEditLoading}
              />
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
