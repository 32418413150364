import { Schema, SchemaProperties } from '@alfred-co/types';

export interface PhotoItem {
  key: string
  title: string
  uri: string[]
  status: 'none' | 'pending' | 'done' | 'error'
}

export const setValuesRecursive = (
  items: PhotoItem[],
  properties: { [key: string]: SchemaProperties },
  values: any
): any => {
  Object.entries(properties).forEach(([key, props]) => {
    if (props.widget === 'image' || props.$ref?.includes('photo')) {
      items.forEach((item) => {
        values[item.key] = [...(item.uri ?? [])];
      });
    }
    if (props.items?.properties) {
      values = {
        ...values,
        [key]: {
          ...values[key],
          ...setValuesRecursive(items, props.items.properties, values),
        },
      };
    }
  });
  return values;
};

export const getRecursivePhotos = (
  properties: {
    [key: string]: SchemaProperties
  },
  values: any
) => {
  const photos: PhotoItem[] = [];
  Object.entries(properties).forEach(([key, props]) => {
    if (props.widget === 'image' || props.$ref?.includes('photo')) {
      photos.push({
        key,
        uri: values[key],
        title: props.title ?? '',
        status: values[key]?.length ? 'done' : 'none',
      });
    }
    if (props.items?.properties) {
      photos.push(...getRecursivePhotos(props.items.properties, values));
    }
  });
  return photos;
};

interface Errors {
  [key: string]: Errors | string
}

export const hasAnyError = (errors: Errors, hasError: boolean = false) => {
  Object.values(errors).forEach((value) => {
    if (typeof value === 'string') {
      hasError = true;
    } else {
      const res = hasAnyError(value, hasError);
      if (res) {
        hasError = true;
        return true;
      }
    }
  });
  return hasError;
};

export const validateErrors = (
  schemaProps: { [key: string]: SchemaProperties },
  required: string[],
  values: any,
  schema: Schema
) => {
  let errors: Errors = {};

  required.forEach((key: string) => {
    if (key === 'end_trip' || key === 'start_trip') return;
    if (key && (!values[key] || (Array.isArray(values[key]) && !values[key].length))) {
      errors[key] = `El campo ${schema?.properties?.[key]?.title ?? schema?.properties?.[key]?.label ?? key} es requerido`;
    }
  });

  Object.keys(schemaProps ?? {}).forEach((key: string) => {
    const props = schema?.properties?.[key];
    const validateValues = values?.[key];

    if (props?.items?.properties) {
      errors = {
        ...errors,
        [key]: validateErrors(
          props?.items?.properties,
          props?.items?.required ?? [],
          values[key],
          schema?.properties?.[key]?.items ?? {}
        ),
      };
    }

    if (props?.widget === 'image' || props?.$ref?.includes('photo')) {
      // phothos limit validation
      if (
        schema?.properties?.[key]?.minLength &&
        (validateValues?.length < schema?.properties?.[key]?.minLength ||
          !validateValues?.length)
      ) {
        errors[key] =
          `La cantidad mínima de fotos permitida es ${schema?.properties?.[key]?.minLength}`;
      } else if (
        schema?.properties?.[key]?.maxLength &&
        validateValues?.length > 0 &&
        (validateValues?.length > schema?.properties?.[key]?.maxLength ||
          !validateValues?.length)
      ) {
        errors[key] =
          `La cantidad máxima de fotos permitida es ${schema?.properties?.[key]?.maxLength}`;
      }
    }
  });
  return errors;
};

export const verifyDefaultValues = (schema: Schema, values: any) => {
  const defaultValues: any = values ?? {};

  Object.entries(schema.properties ?? {}).forEach(([key, props]) => {
    if (props?.default && !values[key]) {
      defaultValues[key] = props.default;
    }
    if (props?.items) {
      defaultValues[key] = verifyDefaultValues(props.items, values?.[key] ?? {});
    }
  });
  return defaultValues;
};
