/* eslint-disable @next/next/no-img-element */
'use client';
import { useEffect, useState } from 'react';
import { JSONSchemaData, JSONSchemaProps } from '@alfred-co/types';
import { twMerge } from 'tailwind-merge';
import Image from 'next/image';
import { CheckboxGroup, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Tooltip } from '@nextui-org/react';
import { Button } from '../Button';
import { Input } from '../Input';
import { verifyDefaultValues, hasAnyError, validateErrors } from './helpers';
import { Checkbox } from '../Checkbox';
import { Radio, RadioGroup } from '../Radio';
import { IconInfo } from '../../icons';
import { Select, SelectItem } from '../Select';
import { FileDropzone } from '../DropzoneMulti';
import { DateInput } from '../DateInput';
import { parseDate } from '@internationalized/date';
import { IMAGE_FORMATS, VIDEO_FORMATS } from '../../utils/index';

interface Errors {
  [key: string]: Errors | string
}

export const JsonSchema = ({
  schema,
  onSave,
  isSubGroup,
  values: rValues,
  errors: rErrors,
  setValues: rSetValues,
  schemaKey: rKey,
  isLoading,
}: JSONSchemaProps) => {
  const [values, setValues] = useState<any>(rValues ?? {});
  const [imageModal, setImageModal] = useState<File | string | undefined>();
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState('');
  const [errors, setErrors] = useState<Errors>(rErrors ?? {});
  const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);

  // set all default values
  useEffect(() => {
    let entryValues: JSONSchemaData = rValues ?? {};
    if (!schema?.properties) return;
    entryValues = verifyDefaultValues(schema, entryValues);
    setValues(entryValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setErrors(rErrors ?? {});
  }, [rErrors, rValues]);

  if (!schema?.properties) {
    return (
      <div>
        No schema
      </div>
    );
  }

  const handleSelectImage = (uri: File | string, key: string) => {
    setSelectedKey(key);
    setImageModal(uri);
    setIsImageModalOpen(true);
  };

  const handleChange = (key: string, value: any) => {
    if (rSetValues && rKey) {
      const newValues = { ...values, [key]: value };
      rSetValues((v: any) => ({ ...(v ?? {}), [rKey]: newValues }));
    }
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete errors[key];
    setValues({ ...values, [key]: value });
    setErrors({ ...errors });
  };

  const handleSave = () => {
    let errors: Errors = {};

    if (schema?.properties) {
      errors = {
        ...errors,
        ...validateErrors(
          schema.properties,
          schema?.required ?? [],
          values,
          schema
        ),
      };
    }

    if (hasAnyError(errors)) {
      setErrors(errors);
      return;
    }

    setErrors({});
    onSave(values);
    setValues({});
  };

  const handleDeleteImage = (uri: string | File) => {
    values[selectedKey] = values[selectedKey].filter((p: File | string) => p !== uri);
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete errors[selectedKey];
    setErrors({ ...errors });
    setIsImageModalOpen(false);
  };

  const before = '- ';

  return (
    <div
      className='flex flex-col gap-4 mt-5 pl-5 w-full'
      id='json-schema'
    >
      <div className='text-lg text-primary'>{schema.title}</div>
      {Object.entries(schema.properties)
        .sort(
          ([key1], [key2]) =>
            (schema?.properties?.[key1]?.order ?? Infinity) -
            (schema?.properties?.[key2]?.order ?? Infinity)
        )
        .map(([key, props], i) => {
          if (
            props.type === 'hidden' ||
            key === 'end_trip' ||
            key === 'start_trip'
          ) {
            return null;
          }

          if (props.items) {
            return (
              <div key={i}>
                <JsonSchema
                  schema={props.items}
                  onSave={(data) => {
                    setValues({ ...values, [key]: data });
                  }}
                  values={values[key]}
                  setValues={setValues}
                  errors={typeof errors[key] === 'object' ? errors[key] : {}}
                  isSubGroup
                  schemaKey={key}
                />
              </div>
            );
          }

          if (props.widget === 'image' || props.$ref?.includes('photo')) {
            return (
              <div key={i}>
                <div className='flex flex-row items-center justify-start gap-2 mb-1'>
                  <div className='text-left'>
                    {before}
                    {props.title ?? props.label}
                  </div>
                  <After tooltip={props?.tooltip ?? ''} />
                </div>

                <div
                  className={
                    twMerge('min-h-28 bg-complementary-gray-main rounded-md border-2 border-gray-400 mb-2 border-dashed flex flex-wrap gap-5 p-2 items-center',
                      !!errors[key] && 'border-error')
                  }
                >
                  <FileDropzone
                    width={200}
                    onChange={(files) => {
                      if (!files) return;
                      handleChange(key, [...(values[key] ?? []), ...files]);
                    }}
                    hideFiles
                    Accept={{ 'image/*': [], 'video/*': [] }}
                  />
                  {!values[key]?.length && props.placeholder && (
                    <div className='text-gray-500 text-sm h-full'>
                      {props.placeholder}
                    </div>
                  )}
                  {values?.[key]?.map((uri: File | string, i: number) => {
                    const isFile = uri instanceof File;
                    if (isFile) {
                      return (
                        <div key={i} className='rounded'>
                          <div onClick={() => handleSelectImage(uri, key)}>
                            {
                              uri.type.includes('video')
                                ? (
                                  <video
                                    src={URL.createObjectURL(uri)}
                                    className='rounded h-20 w-20 object-cover cursor-pointer'
                                  />)
                                : (
                                  <img
                                    src={URL.createObjectURL(uri)}
                                    alt='photo'
                                    className='rounded h-20 w-20 object-cover cursor-pointer'
                                  />)
                            }
                          </div>
                        </div>
                      );
                    }
                    const isImageFile = typeof uri === 'string' && IMAGE_FORMATS.some((ext) => uri.includes(ext));
                    if (isImageFile) {
                      return (
                        <div key={i} className='rounded'>
                          <div onClick={() => handleSelectImage(uri, key)}>
                            <img
                              src={uri}
                              alt='photo'
                              className='rounded h-20 w-20 object-cover cursor-pointer'
                            />
                          </div>
                        </div>
                      );
                    }
                    const isVideoFile = typeof uri === 'string' && VIDEO_FORMATS.some((ext) => uri.includes(ext));
                    if (isVideoFile) {
                      return (
                        <div key={i} className='rounded'>
                          <div onClick={() => handleSelectImage(uri, key)}>
                            <video
                              src={uri}
                              className='rounded h-20 w-20 object-cover cursor-pointer'
                            />
                          </div>
                        </div>
                      );
                    }
                    return (
                      <div key={i} className='rounded'>
                        <div onClick={() => handleSelectImage(uri, key)}>
                          <div className='flex flex-row items-center justify-center gap-2 bg-gray-100 h-20 w-20 rounded'>
                            <div className='text-white text-2xl'>📎</div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                </div>
                {!!errors[key] && (
                  <div className='text-error text-sm'>
                    {typeof errors[key] === 'string' ? errors[key] : ''}
                  </div>
                )}
              </div>
            );
          }
          if (props.widget === 'radio' && props.enum && props.enum.length > 0) {
            return (
              <div key={i}>
                <div className='flex flex-row items-center justify-start gap-2 mb-1'>
                  <div className='text-left'>
                    {before}
                    {props.title ?? props.label}
                  </div>
                  <After tooltip={props?.tooltip ?? ''} />
                </div>
                <RadioGroup
                  color='alfred'
                  value={values[key] ?? props.default}
                  onValueChange={(newValue) => handleChange(key, newValue)}
                  classNames={{ wrapper: 'flex flex-wrap flex-row gap-3' }}
                >
                  {props.enum?.map((item: any, i: number) => (
                    <Radio
                      color='alfred'
                      key={i}
                      value={item}
                    >
                      {item}
                    </Radio>
                  )) ?? []}
                </RadioGroup>
                {!!errors[key] && (
                  <div className='text-error text-sm'>
                    {typeof errors[key] === 'string' ? errors[key] : ''}
                  </div>
                )}
              </div>
            );
          }
          if (props.widget === 'checkBox' && props.uniqueItems && props.enum && props.enum.length > 0) {
            return (
              <div key={i}>
                <div className='flex flex-row items-center justify-start gap-2 mb-1'>
                  <div className='text-left'>
                    {before}
                    {props.title ?? props.label}
                  </div>
                  <After tooltip={props?.tooltip ?? ''} />
                </div>
                <CheckboxGroup
                  value={values[key] ?? props.default}
                  onValueChange={(newValue) => handleChange(key, newValue)}
                >
                  {props.enum?.map((item: any, i: number) => (
                    <Checkbox
                      className=''
                      color='alfred'
                      key={i}
                    > {item}
                    </Checkbox>
                  )) ?? []}
                </CheckboxGroup>
              </div>
            );
          }
          if (props.widget === 'checkBox' && !props.uniqueItems && props.enum && props.enum.length > 0) {
            const orderValues = Object.keys(values[key] ?? {}).filter((k) => k);
            return (
              <div key={i}>
                <div className='flex flex-row items-center justify-start gap-2 mb-1'>
                  <div className='text-left'>
                    {before}
                    {props.title ?? props.label}
                  </div>
                  <After tooltip={props?.tooltip ?? ''} />
                </div>
                <CheckboxGroup
                  value={orderValues}
                  onValueChange={(newValue) => {
                    const value = newValue.reduce((acc: any, item: any) => {
                      acc[item] = true;
                      return acc;
                    }, {});
                    handleChange(key, value);
                  }}
                  classNames={{
                    wrapper: 'flex flex-wrap flex-row gap-4',
                  }}
                  defaultValue={orderValues}
                >
                  {props.enum?.map((item: any, i: number) => (
                    <Checkbox
                      color='alfred'
                      key={i}
                      value={item}
                      classNames={{
                        base: 'w-1/2 max-w-[50%] basis-1/2',
                      }}
                      aria-label={item}
                    >
                      {item}
                    </Checkbox>
                  )) ?? []}
                </CheckboxGroup>
              </div>
            );
          }
          if (props.widget === 'select' || (props.enum && props.enum.length > 0)) {
            return (
              <div key={i}>
                <div className='flex flex-row items-center justify-start gap-2 mb-1'>
                  <div className='text-left'>
                    {before}
                    {props.title ?? props.label}
                  </div>
                  <After tooltip={props?.tooltip ?? ''} />
                </div>
                <Select
                  aria-label='Select'
                  color='alfred'
                  onSelectionChange={(item) => handleChange(key, item.currentKey)}
                  defaultSelectedKeys={[values[key] ?? props.default]}
                  selectedKeys={[values[key] ?? props.default]}
                  variant='bordered'
                >
                  {props.enum?.map((item: string, i: number) => (
                    <SelectItem
                      color='alfred'
                      key={item}
                      value={item}
                    >
                      {item}
                    </SelectItem>
                  )) ?? []}
                </Select>
                {!!errors[key] && (
                  <div className='text-error text-sm'>
                    {typeof errors[key] === 'string' ? errors[key] : ''}
                  </div>
                )}
              </div>
            );
          }
          if (props.widget === 'sign') {
            return (
              <div key={i}>
                <div className='flex flex-row items-center justify-start gap-2 mb-1'>
                  <div className='text-left'>
                    {before}
                    {props?.title ?? props.label}
                  </div>
                  <After tooltip={props?.tooltip ?? ''} />
                </div>
                {values?.[key] && (
                  <div className='flex flex-row items-center justify-center gap-2'>
                    <Image
                      src={values?.[key] ?? ''}
                      alt='signature'
                      width={80}
                      height={80}
                      className='rounded'
                    />
                  </div>
                )}
                <Button
                  onClick={() => {
                    setIsSignatureModalOpen(true);
                    setSelectedKey(key);
                  }}
                  color='secondary'
                >Firmar
                </Button>

                {!!errors[key] && (
                  <div className='text-error text-sm'>
                    {typeof errors[key] === 'string' ? errors[key] : ''}
                  </div>
                )}
              </div>
            );
          }
          if (props.type === 'date') {
            let dateValue = values?.[key] ?? props.default;
            if (dateValue) {
              dateValue = parseDate(values?.[key] ?? props.default);
            }
            return (
              <div key={i}>
                <div style={{ marginTop: 15 }}>
                  <div className='flex flex-row items-center justify-start gap-2 mb-1'>
                    <div className='text-left'>
                      {before}
                      {props.title ?? props.label}
                    </div>
                    <After tooltip={props?.tooltip ?? ''} />
                  </div>
                  <DateInput
                    color='default'
                    value={dateValue}
                    onChange={(e) => handleChange(key, e.toString())}
                    aria-label='Date'
                  />
                  {!!errors[key] && (
                    <div className='text-error text-sm'>
                      {typeof errors[key] === 'string' ? errors[key] : ''}
                    </div>
                  )}
                </div>
              </div>
            );
          }
          if (props.type === 'boolean') {
            return (
              <div key={i}>
                <div className='flex flex-row items-center justify-start gap-2 mb-1'>
                  <div className='text-left'>
                    {before}
                    {props.title ?? props.label}
                  </div>
                  <After tooltip={props?.tooltip ?? ''} />
                </div>
                <Checkbox
                  color='alfred'
                  value={values[key] ?? props.default}
                  onChange={(e) => handleChange(key, e.target.checked)}
                  defaultSelected={values[key] ?? props.default}
                >
                  {props.title ?? props.label}
                </Checkbox>
              </div>
            );
          }
          if (props.type === 'integer' || props.type === 'number') {
            return (
              <div key={i}>
                <div className='flex flex-row items-center justify-start gap-2 mb-1'>
                  <div className='text-left'>
                    {before}
                    {props.title ?? props.label}
                  </div>
                  <After tooltip={props?.tooltip ?? ''} />
                </div>
                <Input
                  color='alfred'
                  type='number'
                  onChange={(e) => {
                    handleChange(key, e.target.value);
                    if (props.maximum && Number(e.target.value) > props.maximum) {
                      setErrors({
                        ...errors,
                        [key]: `El valor máximo permitido es ${props.maximum}`,
                      });
                      return;
                    }
                    if (props.minimum && Number(e.target.value) < props.minimum) {
                      setErrors({
                        ...errors,
                        [key]: `El valor mínimo permitido es ${props.minimum}`,
                      });
                    }
                  }}
                  value={values[key] ?? props.default}
                  placeholder={props?.placeholder ?? ''}
                  maxLength={props?.maxLength}
                  errorMessage={errors[key] as string}
                />
                {!!errors[key] && (
                  <div className='text-error text-sm'>
                    {typeof errors[key] === 'string' ? errors[key] : ''}
                  </div>
                )}
              </div>
            );
          }
          if (props.type === 'string') {
            return (
              <div key={i}>
                <div className='flex flex-row items-center justify-start gap-2 mb-1'>
                  <div className='text-left'>
                    {before}
                    {props.title ?? props.label}
                  </div>
                  <After tooltip={props?.tooltip ?? ''} />
                </div>
                <Input
                  color='alfred'
                  onChange={(e) => {
                    handleChange(key, e.target.value);
                    if (props.maxLength && e.target.value.length > props.maxLength) {
                      setErrors({
                        ...errors,
                        [key]: `El valor máximo de caracteres permitido es ${props.maxLength}`,
                      });
                      return;
                    }
                    if (props.minLength && e.target.value.length < props.minLength) {
                      setErrors({
                        ...errors,
                        [key]: `El valor mínimo de caracteres permitido es ${props.minLength}`,
                      });
                    }
                  }}
                  value={values[key] ?? props.default}
                  placeholder={props?.placeholder ?? ''}
                  maxLength={props?.maxLength}
                />
                {!!errors[key] && (
                  <div className='text-error text-sm'>
                    {typeof errors[key] === 'string' ? errors[key] : ''}
                  </div>
                )}
              </div>
            );
          }
          return (
            <div key={i}>
              <div>{key}</div>
              <div className='text-left'>
                {before}
                {props.title}
              </div>
              <div>{JSON.stringify(props, null, 2)}</div>
            </div>
          );
        })}

      {!isSubGroup && (
        <Button
          onClick={handleSave}
          color='primary'
          isDisabled={hasAnyError(errors)}
          isLoading={isLoading}
        > Guardar
        </Button>
      )}

      <Modal
        isOpen={isImageModalOpen}
        onClose={() => {
          setIsImageModalOpen(!isImageModalOpen);
          setImageModal(undefined);
        }}
        size='2xl'
        classNames={{
          base: ['bg-frosted-gradient backdrop-blur frosty text-white rounded-lg border-1 border-complementary-gray-light'],
          closeButton: ['bg-dark-dark hover:bg-dark-main p-1 m-1 [&>svg]:h-5 [&>svg]:w-5'],
        }}

      >
        <ModalContent>
          <ModalHeader>
            Imagen
          </ModalHeader>
          <ModalBody>
            <div className='flex items-center justify-center'>
              {
                imageModal && imageModal instanceof File &&
                  <img
                    src={URL.createObjectURL(imageModal)}
                    alt='photo'
                    className='rounded object-cover cursor-pointer align-middle'
                  />
              }
              {imageModal && typeof imageModal === 'string' && IMAGE_FORMATS.some((ext) => imageModal.includes(ext)) &&
                <img
                  src={imageModal}
                  alt='photo'
                  className='rounded object-cover cursor-pointer align-middle'
                />}
              {imageModal && typeof imageModal === 'string' && VIDEO_FORMATS.some((ext) => imageModal.includes(ext)) &&
                <video
                  src={typeof imageModal === 'string' ? imageModal : URL.createObjectURL(imageModal)}
                  className='rounded object-cover cursor-pointer align-middle'
                  controls
                />}
            </div>
          </ModalBody>
          <ModalFooter className='justify-center items-center px-10 gap-10'>
            <Button
              color='danger'
              fullWidth
              onPress={() => handleDeleteImage(imageModal ?? '')}
            >Borrar
            </Button>
            <Button
              color='secondary'
              fullWidth
              onPress={() => setIsImageModalOpen(false)}
            >Cerrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isSignatureModalOpen}
        onClose={() => {
          setIsSignatureModalOpen(!isSignatureModalOpen);
        }}
        portalContainer={document.getElementById('json-schema') ?? undefined}
        classNames={{
          backdrop: 'w-full h-full',
          wrapper: 'w-full h-full',
        }}
        scrollBehavior='inside'
      >
        <ModalContent>
          <ModalHeader>
            Firma
          </ModalHeader>
          <ModalBody>Body</ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default JsonSchema;

const After = ({ tooltip }: { tooltip: string }) => {
  return (
    <div>
      {tooltip && (
        <Tooltip
          content={tooltip}
        >
          <div>
            <IconInfo />
          </div>
        </Tooltip>
      )}
    </div>
  );
};
