'use client';
import { ModalHeader, Modal, useDisclosure, ModalContent, CircularProgress, Button, ModalBody } from '.';
import Image from 'next/image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Zoom } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/zoom';
import '../styles/customSwiper.css';
import { useCallback, useEffect, useState } from 'react';
import { IconBackNavigate, IconCancel } from '../icons';
import { EventTrackingModel, Schema } from '@alfred-co/types';
import { toWeekDayDateTime } from '../utils';
import { twMerge } from 'tailwind-merge';
import { IMAGE_FORMATS } from '../utils';

interface EventDataModalProps {
  isOpen?: boolean
  onClose?: () => void
  labor: string
  title: string
  event: EventTrackingModel
  isTransport?: boolean
}

export const EventDataModal = ({ isOpen: isOpenI, onClose: onCloseI, labor, title, event, isTransport }: EventDataModalProps) => {
  const { isOpen, onOpenChange } = useDisclosure({
    ...isOpenI && { isOpen: isOpenI },
    ...onCloseI && { onClose: onCloseI },
  });
  const [isImages, setisImages] = useState(false);
  const [images, setImages] = useState<string[]>([]);
  const [subTitle, setSubTitle] = useState('');

  const handleSeeImages = useCallback((images: string[], subTitle: string) => {
    setisImages(true);
    setSubTitle(subTitle);
    const imagesArray = typeof images === 'string' ? [images] : images;
    const clearImages = imagesArray.filter((image) => image?.includes('http'));
    setImages(clearImages);
  }, []);

  useEffect(() => {
    if (isTransport) {
      setisImages(true);
      setSubTitle(event.eventType.name);
      const images = Object.entries(event.data).map(([key, value], index) => {
        if (value === null) return null;
        if (key.includes('photo')) {
          return typeof value === 'string' ? [value] : value;
        }
        return null;
      }).filter((image) => image);
      setImages(images[0]);

      if (images.length === 0) {
        setisImages(false);
      }
    }
  }, [isTransport, event.data, event.eventType.name]);

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      isDismissable={false}
      isKeyboardDismissDisabled
      size='3xl'
      classNames={{
        backdrop: 'z-[100000]',
        wrapper: 'z-[100001]',
        base: ['bg-frosted-gradient backdrop-blur frosty text-white rounded-lg border-1 border-complementary-gray-light h-full'],
        closeButton: ['bg-dark-dark hover:bg-dark-main p-1 m-1 [&>svg]:h-5 [&>svg]:w-5'],
      }}
      scrollBehavior='inside'
    >
      <ModalContent>
        <ModalHeader className='flex flex-col items-start gap-1'>
          {title}
        </ModalHeader>
        <ModalBody>
          <div className='flex flex-col my-5 h-full'>
            {
              !isImages && (
                <>
                  <div className='bg-secondary text-secondary-50 bg-clip-text mb-6 font-gothamBold uppercase'>
                    {labor}
                  </div>
                  <div className='flex flex-col flex-grow w-full pb-10 gap-5'>
                    <SchemaDataBody
                      data={event.data}
                      schema={event.eventType.schema}
                      handleSeeImages={handleSeeImages}
                    />
                  </div>
                </>
              )
            }
            {
              isImages && (
                <>
                  <div className='flex flex-row justify-between items-center gap-10'>
                    {!isTransport &&
                      <div
                        className='ml-3 flex flex-row items-center gap-3 text-secondary cursor-pointer hover:text-secondary-light group'
                        onClick={() => { setisImages(false); setSubTitle(''); }}
                      >
                        <div className='h-8 w-8 bg-complementary-gray-main cursor-pointer rounded-full ml-2 group-hover:bg-complementary-gray-dark'>
                          <IconBackNavigate className='cursor-pointer' />
                        </div>
                        Atrás
                      </div>}
                    <div className='text-secondary'>{subTitle}</div>
                    <div className='min-w-24' />
                  </div>
                  <Swiper
                    navigation
                    pagination={{
                      clickable: true,
                    }}
                    centeredSlides
                    zoom={{ maxRatio: 2 }}
                    modules={[Navigation, Pagination, Zoom]}
                    className='h-full w-full'
                  >
                    {
                      images.filter(i => i !== null).map((image: string, index: number) => (
                        <SwiperSlide key={index}>
                          <ImageSlide image={image} />
                        </SwiperSlide>
                      ))
                    }
                  </Swiper>
                </>
              )
            }
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const ImageSlide = ({ image }: { image: string }) => {
  const [loading, setLoading] = useState(true);

  const handleLoadingComplete = () => {
    setLoading(false);
  };

  return (
    <div className='flex h-full w-full items-center justify-center swiper-zoom-container'>
      {
        IMAGE_FORMATS.some((ext) => image?.includes(ext))
          ? (
            <>
              {
                loading && (
                  <CircularProgress aria-label='Loading...' size='lg' color='secondary' />
                )
              }
              <Image
                src={image}
                alt='event-image'
                className='cursor-zoom-in'
                layout='fill'
                objectFit='contain'
                quality={100}
                onLoad={handleLoadingComplete}
              />
            </>
            )
          : (
            <video
              className='h-[90%] w-[90%]'
              controls
              src={image}
            />
            )
      }
    </div>
  );
};

const SchemaDataBody = (
  { data, schema, handleSeeImages }:
  { data: any, schema: Schema, handleSeeImages: (value: any, title: string) => void }
) => {
  return (
    <>
      <div className='text-secondary uppercase font-gothamBold'>- {schema.title}</div>
      <div className='flex flex-col flex-grow w-full pl-6 gap-5'>
        {
          Object.entries(data)
            .sort(
              ([key1], [key2]) => {
                const order1 = schema.properties?.[key1]?.order ?? 0;
                const order2 = schema.properties?.[key2]?.order ?? 0;
                return order1 - order2;
              })
            .map(([key, value]: [string, any], index: number) => {
              const title = schema.properties?.[key]?.title ??
                        schema.properties?.[key]?.label ??
                        key;
              if (key.includes('blocked')) return null;

              if (schema.properties?.[key]?.widget === 'image' || ['photo', 'picture'].some((k) => key.includes(k))) {
                if (value === null) return '-';
                if (!value?.length || typeof value === 'string') {
                  return <EventItem title={title} value='No reportado' key={index + '-' + title} />;
                }
                return (
                  <ImageItem
                    key={index + '-' + title}
                    title={title}
                    onClick={() => handleSeeImages(value, title)}
                  />
                );
              }
              if (typeof value === 'object' && value !== null && schema.properties?.[key]?.items) {
                return (
                  <SchemaDataBody data={value} schema={schema.properties?.[key]?.items} handleSeeImages={handleSeeImages} key={index + '-' + title} />
                );
              }

              if (typeof value === 'boolean') {
                value = value ? 'Si' : 'No';
              }
              if (value === 'true') {
                value = 'Si';
              }
              if (value === 'false') {
                value = 'No';
              }
              if (value === 'null') {
                value = 'No disponible';
              }
              if (Array.isArray(value)) {
                value = value.join(', ');
              }
              if (schema.properties?.[key]?.type === 'date' && value && typeof value === 'string') {
                value = toWeekDayDateTime(new Date(value));
              }

              return (
                <EventItem title={title} value={typeof value === 'object' ? JSON.stringify(value) : value} key={index + '-' + title} />
              );
            })
        }
      </div>
    </>
  );
};

const ImageItem = ({ title, onClick }: { title: string, onClick: () => void }) => {
  return (
    <div className='flex flex-row items-center gap-2'>
      <div className='w-1/2 font-gothamBook'>{title ?? 'Imagen'}:</div>
      <div className='font-gothamBook w-1/2'>
        <Button
          color='secondary'
          size='sm'
          onClick={onClick}
        >
          Ver Evidencias
        </Button>
      </div>
    </div>
  );
};

const EventItem = ({ title, value }: { title: string, value: any }) => {
  if (value === null) return null;
  if (typeof value === 'boolean') {
    value = value ? 'Si' : 'No';
  }
  if (value === 'true') {
    value = 'Si';
  }
  if (value === 'false') {
    value = 'No';
  }
  if (value === 'null') {
    value = 'No disponible';
  }

  const isCritic = typeof value === 'string' && value.toLowerCase().includes('crítico');

  return (
    <div className='flex flex-row items-center gap-3'>
      <div className={twMerge('w-1/2 font-gothamBook', isCritic && 'text-error')}>{title}:</div>
      <div className={twMerge('font-gothamBold w-1/2 flex flex-row gap-2 items-center', isCritic && 'text-error')}>
        {
          isCritic && (
            <div className='p-1 rounded-[5px] bg-error/40'>
              <IconCancel />
            </div>
          )
        }
        {value}
      </div>
    </div>
  );
};
